<template>
  <v-autocomplete
    clearable
    v-model="entity"
    :items="listaItensSelect"
    :label="setLabel(typesEntities)"
    v-on:keyup="searchAutoComplete"
    :search-input.sync="searchText"
    :loading="isLoadingAutoComplete"
    :error-messages="error || []"
    return-object
    :disabled="disabled"
    dense />
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import { errorSnackbar } from '@/core/service/utils'
import Event from '@/core/service/events'
import { filter, size } from 'lodash'
export default {
  name: 'AutoCompleteEntities',
  props: ['entityId', 'typesEntities', 'label', 'type', 'error', 'value', 'disabled'],
  data: () => ({
    isLoadingAutoComplete: false,
    modelAutoComplete: null,
    searchText: null,
    loading: false,
    entity: null,
    debounce: null
  }),

  mounted () {
    const vm = this
    // this.getEntities(this.typesEntities).then(()=>{}).catch((e)=>{console.log(e)})
    this.entity = this.value
    Event.$on('update:entity::bug', e => {
      vm.entity = e
    })
  },

  watch: {
    entity (val) {
      this.$emit('update:entity', val)
    },
    value (val) {
      this.entity = val
    }
  },

  computed: {
    ...mapGetters('preCaixa', ['pList', 'vList', 'pEndList', 'vEndList', 'listaEntitiesTypes']),
    listaItensSelect () {
      if (this.type === 'p') return this.pList
      if (this.type === 'v') return this.vList
      if (this.type === 'vEnd') return this.vEndList
      if (this.type === 'pEnd') return this.pEndList
      return []
    }
  },

  methods: {
    ...mapActions('preCaixa', { 'filterEntities': 'getLista' }),
    setLabel (val = null) {
      if (val === null) return ''
      let types = filter(this.listaEntitiesTypes, v => v.id === parseInt(val[0]))
      if (size(types)) {
        return this.formatLabel(types[0].name) || 'Procurar'
      }
    },
    formatLabel(v) {
      return String(v).charAt(0).toUpperCase() + String(v).substr(1).toLowerCase()
    },
    searchAutoComplete(event) {
      switch (event.keyCode) {
        case 37: // Left 
        case 38: // Up 
        case 39: // Right 
        case 40: // Down 
        case 13: // Enter 
        case 17: // Ctrl 
        case 18: // Alt 
        case 92: // Meta 
          return
      }

      clearTimeout(this.debounce)
      const vm = this
      this.debounce = setTimeout(() => {
        if (vm.searchText === null || vm.searchText.length === 0) {
          vm.pesquisar('')
          return
        }

        vm.pesquisar(vm.searchText)
      }, 600)
    },

    pesquisar (val) {
      this.isLoadingAutoComplete = true
      let params = {}
      if ((String(this.type).substr(0, 1) === 'v' || String(this.type).substr(0, 1) === 'p') && String(val).replace(/\D/g, '').length !== 0) {
        params['Entities:cod_ilike'] = `%${String(val).replace(/\D/g, '')}%`
      } else {
        params["Entities:jsonData.name_ilike"] = `%${val}%`
      }
      this.getEntities(this.typesEntities, params)
        .catch(err => {
          errorSnackbar(err.error)
        })
        .finally(() => this.isLoadingAutoComplete = false)
    },

    async getEntities (types, filter = {}) {
      const response = await this.filterEntities({  origin: 'cachIn', type: this.type ,_filter: { 'entityTypeId_In': types.toString(), ...filter }, pageSize: this.$store.getters.ItensPaginacao , order: { 'cod::int': 'asc' } })
      return Promise.resolve(response.resultMap)
    }
  }
}
</script>